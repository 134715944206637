<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />

    <v-data-table
        class="w-auto"
        item-key="id"
        dense
        :headers="headersTalhoes"
        :items="item.talhoes"
        :items-per-page="10"
        :mobile-breakpoint="0"
        v-if="item.talhoes.length > 0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.quantidade`]="{ item }">
        <span>{{ $stringFormat.formatNumber(item.quantidade) }}</span>
      </template>

    </v-data-table>

    <v-data-table
        class="w-auto"
        item-key="seqipo"
        dense
        :headers="headersItens"
        :items="item.produtos"
        :items-per-page="10"
        :mobile-breakpoint="0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.quantidade`]="{ item }">
        <span>{{ $stringFormat.formatNumber(item.quantidade) }}</span>
      </template>

    </v-data-table>

    <hr/>

    <v-row class="mb-4">
      <v-col cols="12" class="font-weight-bold pb-0">{{ item.utilizacao_externa ? 'Justificativa' : 'Observação'}}:</v-col>
      <v-col cols="12">{{ item.observacao || '-' }}</v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
    },
  },

  computed: {
    headersTalhoes () {
      return [
        { text: 'Talhão', value: 'nome' },
      ]
    },
    headersItens () {
      return [
        { text: 'Código Produto', value: 'produto_id' },
        { text: 'Produto', value: 'produto' },
        { text: 'Quantidade', value: 'quantidade', align: 'right' },
        { text: 'Unidade Medida', value: 'unidade_medida', align: 'left' },
      ]
    },
  },
  methods: {
    converteUnidadeMedida (unidadeMedida, valor) {
      if (unidadeMedida === 'TN') {
        return parseFloat(valor) * 1000
      }

      return parseFloat(valor)
    },
  },
}
</script>
