<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="800">
    <custom-snackbar ref="snackbar" />
    <v-card>
      <v-toolbar dark color="info">
        <span class="headline">Lançamento de Consumo de Insumo(s)</span>
      </v-toolbar>

      <v-form ref="form" lazy-validation v-show="showForm">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <EmpresasSelect
                    v-model="form.empresaId"
                    obrigatorio
                />
              </v-col>
              <v-col cols="6">
                <ProducoesSelect
                    v-model="form.producaoId"
                    :empresa="form.empresaId"
                    obrigatorio
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                    label="Data de Realização"
                    v-model="form.dataRealizacao"
                    :rules="dataRule"
                    :max="dataAtual"
                />
              </v-col>
              <v-col cols="6">
                <bloco-informativo :right="false" bottom textoInformativo="Necessario justificativa: exemplo: veiculos não cadastrados ou outros usos.">
                  <v-switch
                    v-model.number="form.utilizacaoExterna"
                    inset
                    color="success"
                    class="mt-0 pt-0"
                >
                  <template v-slot:label>
                    Aplicação Área Externa:
                    <custom-label
                        class="ml-2"
                        :color="!form.utilizacaoExterna ? 'danger' : 'success'"
                        :text="!form.utilizacaoExterna ? 'Não' : 'Sim'"
                    />
                  </template>
                </v-switch>
                </bloco-informativo>
              </v-col>

              <v-col cols="12">
                <TalhoesSelect
                    v-model="form.talhoes"
                    :empresa="form.empresaId"
                    :producao="form.producaoId"
                    :disabled="form.utilizacaoExterna"
                    :obrigatorio="!form.utilizacaoExterna"
                    readonly
                    multiple
                />
              </v-col>
            </v-row>
            <v-card class="mb-5">
              <v-card-title>
                Produtos

                <v-btn
                    icon
                    class="ml-1"
                    color="dark d-flex flex-row-reverse"
                    @click="produtosAddRow"
                    :disabled="!form.empresaId || !form.producaoId"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-tooltip right transition="v-slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        class="ml-1"
                        color="dark d-flex flex-row-reverse"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleShowDelete"
                        :disabled="form.produtos.length === 1"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>

                  <span>Exibe ícone de para apagar produtos individualmente</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <v-row class="mt-2" v-for="(produto, index) in form.produtos" :key="index">
                  <v-col class="col-6">
                    <ProdutoSelect
                        :empresa="form.empresaId"
                        :producao="form.producaoId"
                        v-model="produto.produto"
                        :disabled="!form.producaoId"
                        estoque
                        return-object
                    >
                      <v-icon
                          dark
                          color="red"
                          slot="icon"
                          v-if="showDeleteButton"
                          @click="produtosDeleteRow(index)"
                      >
                        mdi-minus
                      </v-icon>

                    </ProdutoSelect>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        type="text"
                        :value="$stringFormat.formatNumber(produto.produto? produto.produto.quantidade: 0, 2)"
                        label="Saldo Estoque"
                        disabled
                    />
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        type="number"
                        min="0"
                        step="0.1"
                        :max="produto.produto? produto.produto.quantidade: 0"
                        v-model="produto.quantidade"
                        :rules="quantidadeProdutoRule"
                        :label="'Quantidade' + (produto.produto? ' (' + produto.produto.unidade_medida + ')': '')"
                        :disabled="(produto.produto? produto.produto.quantidade: 0) <= 0"
                        @change="validarQuantidadeProduto(produto.produto? produto.produto.quantidade: 0, produto.quantidade, index)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col cols="12">
                <v-textarea
                    v-model="form.observacao"
                    outlined
                    :label="form.utilizacaoExterna ? 'Justificativa' : 'Observações'"
                    :rules="observacaoRule"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="blue darken-1" @click="close">
            Cancelar
          </v-btn>

          <v-btn text color="blue darken-1" @click="salvar">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-card-text v-show="!showForm">
        <div class="text-center p-5">
          <h4 class="mb-4">
            Aguarde...
          </h4>

          <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: (instance) => ({
    form: {
      empresaId: null,
      producaoId: null,
      dataRealizacao: instance.$date.getISOToday(),
      utilizacaoExterna: null,
      observacao: null,
      talhoes: [],
      produtos: [
        {
          produto: null,
          quantidade: 0,
        },
      ],
    },
    dataAtual: instance.$date.getISOToday(),
    showForm: true,
    showDeleteButton: false,
    dataRule: [(v) => !!v || 'Necessário informar a Data de Realização'],
    produtoRule: [(v) => !!v || 'Necessário informar um Produto'],
    observacaoRule: [(v) => !instance.form.utilizacaoExterna || !!v || 'Necessário informar um Produto'],
    quantidadeProdutoRule: [
      (v) => {
        if (!v || v === 'NaN') {
          return 'Necessário informar a Quantidade do Produto'
        }

        if (parseFloat(v) > 0) {
          return true
        }

        return 'Quantidade do Produto precisa ser maior que 0'
      },
    ],
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (!val) {
          this.reload()
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {

    validarQuantidadeProduto (saldo, quantidade, index) {
      saldo = parseFloat(saldo)
      quantidade = parseFloat(quantidade)

      if (quantidade < 0) {
        this.dialogQuantidadeNegativa = true

        this.$nextTick(() => {
          this.form.produtos[index].quantidade = 0
        })

        return
      }

      if (saldo < quantidade) {
        this.dialogSaldoEstoque = true

        this.$nextTick(() => {
          this.form.produtos[index].quantidade = saldo
        })
      }
    },
    close () {
      this.model = false
    },
    salvar () {
      if (this.$refs.form.validate()) {
        this.showForm = false
        const formulario = {
          empresaId: this.form.empresaId,
          producaoId: this.form.producaoId,
          dataRealizacao: this.form.dataRealizacao,
          utilizacaoExterna: !!this.form.utilizacaoExterna,
          observacao: this.form.observacao ?? '',
          talhoes: !this.form.utilizacaoExterna ? this.form.talhoes : null,
          produtos: this.form.produtos.filter((produto) => !!produto.produto).map((produto) => ({
            id: produto.produto.id,
            quantidade: produto.quantidade,
          })),
        }

        ApiService
          .post('/agricola/lancamento/insumo', formulario)
          .then((response) => {
            this.model = false
            this.showForm = true
            this.$emit('novo-registro', response)
          })
          .catch((error) => {
            this.$refs.snackbar.show('Problema ao salvar formulario', error.message, 'danger', 3000)
            this.showForm = true
          })
      }
    },
    handleShowDelete () {
      this.showDeleteButton = !this.showDeleteButton
    },
    produtosAddRow () {
      this.form.produtos.push({
        produto: null,
        quantidade: 0,
      })
    },
    produtosDeleteRow (index) {
      this.form.produtos.splice(index, 1)

      if (this.form.produtos.length === 1) {
        this.showDeleteButton = false
      }
    },
    reload () {
      this.$refs.form.resetValidation()
      this.showDeleteButton = false

      this.form = {
        empresaId: null,
        producaoId: null,
        dataRealizacao: this.$date.getISOToday(),
        utilizacaoExterna: null,
        observacao: null,
        talhoes: [],
        produtos: [
          {
            produto: null,
            quantidade: 0,
          },
        ],
      }
    },
  },
}
</script>
