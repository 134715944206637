<template>
  <div>
    <v-form ref="form">
      <FiltroMobile :has-change="change" @search="seachFilter">
        <v-col class="pb-0 col-12 col-md-2 col-lg-2">
          <DatePicker label="Data Início" v-model="dataInicio"  @input="change = true"/>
        </v-col>
        <v-col class="pb-0 col-12 col-md-2 col-lg-2">
          <DatePicker label="Data Fim" v-model="dataFim" @input="change = true"/>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <EmpresasSelect
              v-model="empresaId"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <ProducoesSelect
              v-model="producaoId"
              :empresa="empresaId"
              :disabled="!empresaId"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <TalhoesSelect
              :empresa="empresaId"
              :producao="producaoId"
              v-model="talhoes"
              multiple
              :disabled="!producaoId"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <ProdutoSelect
              :empresa="empresaId"
              :producao="producaoId"
              v-model="produtoId"
              :disabled="!empresaId || !producaoId"
              @change="change = true"
          />
        </v-col>
      </FiltroMobile>
    </v-form>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      dataInicio: '',
      dataFim: '',
      empresaId: '',
      producaoId: '',
      talhoes: [],
      produtoId: '',
    }
  },
  mounted () {
    this.dataInicio = this.value?.dataInicio
    this.dataFim = this.value?.dataFim
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.talhoes = this.value?.talhoes
    this.produtoId = this.value?.produtoId
  },
  watch: {
    value (val) {
      this.dataInicio = val?.dataInicio
      this.dataFim = val?.dataFim
      this.empresaId = val?.empresaId
      this.producaoId = val?.producaoId
      this.talhoes = val?.talhoes
      this.produtoId = val?.produtoId
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = {
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        talhoes: this.talhoes,
        produtoId: this.produtoId,
      }

      this.change = false
    },
  },
}
</script>

<style scoped>

</style>
