<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="800">
    <custom-snackbar ref="snackbar" />
    <v-card>
      <v-toolbar dark color="info">
        <span class="headline">Lançamento de Consumo de Insumo(s)</span>
      </v-toolbar>

      <v-form ref="form" lazy-validation v-show="showForm">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <EmpresasSelect
                    v-model="form.empresaId"
                    obrigatorio
                    readonly
                />
              </v-col>
              <v-col cols="6">
                <ProducoesSelect
                    v-model="form.producaoId"
                    :empresa="form.empresaId"
                    obrigatorio
                    readonly
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                    label="Data de Realização"
                    v-model="form.dataRealizacao"
                    :rules="dataRule"
                    :max="dataAtual"
                    disabled
                />
              </v-col>
              <v-col cols="6">
                <bloco-informativo :right="false" bottom textoInformativo="Necessario justificativa.">
                  <v-switch
                      v-model.number="form.utilizacaoExterna"
                      inset
                      color="success"
                      class="mt-0 pt-0"
                      disabled
                  >
                    <template v-slot:label>
                      Aplicação Área Externa:
                      <custom-label
                          class="ml-2"
                          :color="!form.utilizacaoExterna ? 'danger' : 'success'"
                          :text="!form.utilizacaoExterna ? 'Não' : 'Sim'"
                      />
                    </template>
                  </v-switch>
                </bloco-informativo>
              </v-col>

              <v-col cols="12">
                <TalhoesSelect
                    v-model="form.talhoes"
                    :empresa="form.empresaId"
                    :producao="form.producaoId"
                    :disabled="form.utilizacaoExterna"
                    :obrigatorio="!form.utilizacaoExterna"
                    readonly
                    multiple
                />
              </v-col>
            </v-row>
            <v-card class="mb-5">
              <v-card-title>
                Produtos

                <v-tooltip bottom z-index="1000">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        dark
                        small
                        class="ml-2"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>

                  <span>Digite pelo menos 3 letras do nome do produto para buscar</span>
                </v-tooltip>

                <v-btn
                    icon
                    class="ml-1"
                    color="dark d-flex flex-row-reverse"
                    disabled
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-tooltip right transition="v-slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        class="ml-1"
                        color="dark d-flex flex-row-reverse"
                        v-bind="attrs"
                        v-on="on"
                        disabled
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>

                  <span>Exibe ícone de para apagar produtos individualmente</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <v-row class="mt-2" v-for="(produto, index) in form.produtos" :key="index">
                  <v-col class="col-6">
                    <ProdutoSelect
                        :nome="'insumo.' + index"
                        :empresa="form.empresaId"
                        :producao="form.producaoId"
                        v-model="produto.produto"
                        :disabled="!form.producaoId"
                        estoque
                        return-object
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        type="text"
                        :value="$stringFormat.formatNumber(produto.produto? produto.produto.quantidade + produto.quantidadeOriginal: 0, 2)"
                        label="Saldo Estoque"
                        disabled
                    />
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        type="number"
                        min="0"
                        step="0.1"
                        :max="produto.produto? produto.produto.quantidade + produto.quantidadeOriginal: produto.quantidadeOriginal"
                        v-model="produto.quantidade"
                        :rules="quantidadeProdutoRule"
                        :label="'Quantidade' + (produto.produto? ' (' + produto.produto.unidade_medida + ')': '')"
                        :disabled="(produto.produto? produto.produto.quantidade + produto.quantidadeOriginal: produto.quantidadeOriginal) <= 0"
                        @change="validarQuantidadeProduto(produto.produto? produto.produto.quantidade + produto.quantidadeOriginal: produto.quantidadeOriginal, parseFloat(produto.quantidade), index)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col cols="12">
                <v-textarea
                    v-model="form.observacao"
                    outlined
                    :label="form.utilizacaoExterna ? 'Justificativa' : 'Observações'"
                    :rules="observacaoRule"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="blue darken-1" @click="close">
            Cancelar
          </v-btn>

          <v-btn text color="blue darken-1" @click="salvar">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-card-text v-show="!showForm">
        <div class="text-center p-5">
          <h4 class="mb-4">
            Aguarde...
          </h4>

          <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: (instance) => ({
    form: {
      empresaId: null,
      producaoId: null,
      dataRealizacao: instance.$date.getISOToday(),
      utilizacaoExterna: null,
      observacao: null,
      talhoes: [],
      produtos: [
        {
          produto: null,
          quantidade: 0,
        },
      ],
    },
    dataAtual: instance.$date.getISOToday(),
    showForm: true,
    dataRule: [(v) => !!v || 'Necessário informar a Data de Realização'],
    produtoRule: [(v) => !!v || 'Necessário informar um Produto'],
    observacaoRule: [(v) => !instance.form.utilizacaoExterna || !!v || 'Necessário informar um Produto'],
    quantidadeProdutoRule: [
      (v) => {
        if (!v || v === 'NaN') {
          return 'Necessário informar a Quantidade do Produto'
        }

        if (parseFloat(v) > 0) {
          return true
        }

        return 'Quantidade do Produto precisa ser maior que 0'
      },
    ],
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (!val) {
          this.reload()
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    item: function (val) {
      if (!val) {
        return
      }

      this.form = {
        empresaId: val.empresa_id,
        producaoId: val.producao_id,
        talhoes: val.talhoes ? val.talhoes.map((talhao) => talhao.id) : [],
        dataRealizacao: val.data_realizacao.split('/').reverse().join('-'),
        utilizacaoExterna: val.utilizacao_externa,
        observacao: val.observacao,
        produtos: val.produtos.map((produto) => ({
          produto: {
            id: produto.produto_id,
            label: produto.produto,
            quantidade: parseFloat(produto.quantidade),
            unidade_medida: produto.unidade_medida,
          },
          quantidade: parseFloat(produto.quantidade),
          quantidadeOriginal: parseFloat(produto.quantidade),
        })),
      }
    },
  },
  methods: {

    validarQuantidadeProduto (saldo, quantidade, index) {
      saldo = parseFloat(saldo)
      quantidade = parseFloat(quantidade)

      if (quantidade < 0) {
        this.dialogQuantidadeNegativa = true

        this.$nextTick(() => {
          this.form.produtos[index].quantidade = 0
        })

        return
      }

      if (saldo < quantidade) {
        this.dialogSaldoEstoque = true

        this.$nextTick(() => {
          this.form.produtos[index].quantidade = saldo
        })
      }
    },
    close () {
      this.model = false
    },
    salvar () {
      if (this.$refs.form.validate()) {
        this.showForm = false
        const formulario = {
          utilizacaoExterna: this.item.utilizacao_externa,
          observacao: this.form.observacao ?? '',
          produtos: this.form.produtos.filter((produto) => !!produto.produto).map((produto) => ({
            id: produto.produto.id,
            quantidade: parseFloat(produto.quantidade).toFixed(2),
          })),
        }

        ApiService
          .put('/agricola/lancamento/insumo/' + this.item.id, formulario)
          .then((response) => {
            this.model = false
            this.showForm = true
          })
          .catch((error) => {
            this.$refs.snackbar.show('Problema ao salvar formulario', error.message, 'danger', 3000)
            this.showForm = true
          })
      }
    },
    reload () {
      this.form = {
        empresaId: null,
        producaoId: null,
        dataRealizacao: this.$date.getISOToday(),
        utilizacaoExterna: null,
        observacao: null,
        talhoes: [],
        produtos: [
          {
            produto: null,
            quantidade: 0,
          },
        ],
      }
    },
  },
}
</script>