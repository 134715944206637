<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
          ref="tabela"
          dense
          show-expand
          single-expand
          item-key="id"
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :expanded.sync="expanded"
          :headers="headers"
          :items="data"
          :loading="loading"
          :no-data-text="noData"
          :items-per-page.sync="perPage"
          :server-items-length="totalItems"
          :page.sync="page"
          :footer-props="$dataTableConfig.getFooterProps()"
          :loading-text="$dataTableConfig.getLoadText()"
          :mobile-breakpoint="0"
          @update:page="getData"
          @update:items-per-page="getData"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              indeterminate
              color="green"
            />
          </template>

          <template v-slot:[`item.data_realizacao`]="{ item }">
            <span>{{ validDate(item.data_realizacao) }}</span>
          </template>

          <template v-slot:[`item.utilizacao_externa`]="{ item }">
            <custom-label
                :color="getColor(item.utilizacao_externa)"
                :text="getText(item.utilizacao_externa)"
            />
          </template>

          <template v-slot:[`item.talhoes`]="{ item }">
            <div>
              <custom-label
                v-for="(talhao, idx) in item.talhoes.slice(0, 5)"
                class="mr-1"
                :text="talhao.nome"
                :key="idx"
              />
              <custom-label v-if="item.talhoes.length > 5" text="..."/>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                small
                @click="editarRegistro(item)"
                :disabled="loading"
                v-if="can('agricola.insumo.update')"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="cancelarRegistro(item)"
                :disabled="loading"
                v-if="can('agricola.insumo.destroy')"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-spacer />
            <div>
              <v-row class="justify-center mt-1">
                <v-col class="col-lg-6">
                  <p>Não há dados para serem exibidos</p>
                </v-col>
              </v-row>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <ViewComponents :item="item"></ViewComponents>
            </td>
          </template>
        </v-data-table>
    </v-card-text>
    <EditarLancamentoDialog v-model="edicaoDialog" :item="itemSelecionado" @input="getData"></EditarLancamentoDialog>
    <ExcluirLancamentoDialog v-model="cancelamentoDialog" :item="itemSelecionado" @input="getData"></ExcluirLancamentoDialog>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'
import { can } from '@/core/services/storage.service'

import ViewComponents from './ViewComponent'
import ExcluirLancamentoDialog from './ExcluirLancamentoDialog.vue'
import EditarLancamentoDialog from './EditarLancamentoDialog.vue'

export default {
  components: {
    EditarLancamentoDialog,
    ExcluirLancamentoDialog,
    ViewComponents,
  },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    reloadData: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    // General
    loading: false,
    edicaoDialog: false,
    cancelamentoDialog: false,
    noData: 'Nenhuma informação encontrada',
    // Form Data
    data: [],
    // Expand Data Table
    expanded: [],
    itemSelecionado: null,
    perPage: instance.$dataTableConfig.getItemsPerPage(),
    page: 1,
    totalItems: 0,
  }),
  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
    reloadData (val) {
      if (val) {
        this.getData()
        this.reloadData = false
      }

      this.$emit('update:reloadData', val)
    },
  },

  mounted () {
    this.getData()

    this.$refs.tabela.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabela.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    can,
    getData () {
      this.itemSelecionado = null

      if (!this.filtros.dataInicio || !this.filtros.dataFim) {
        return
      }

      this.loading = true
      this.expanded = []

      this.filtros.per_page = this.perPage
      this.filtros.page = this.page

      ApiService
        .cancel('lancamento-insumo')
        .get(
          '/agricola/lancamento/insumo',
          '?' + this.$stringFormat.queryParamsRecursive(this.filtros),
        )
        .then((res) => {
          this.data = res.data.data
          this.totalItems = res.data.meta.total

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    editarRegistro (item) {
      this.edicaoDialog = true
      this.itemSelecionado = item
    },
    cancelarRegistro (item) {
      this.cancelamentoDialog = true
      this.itemSelecionado = item
    },
    validDate (value) {
      if (value == null) {
        return '-'
      }

      return value
    },
    getColor (boolField) {
      return boolField === 0 || boolField === false ? 'danger' : 'success'
    },

    getText (aplicacaoParcial) {
      return aplicacaoParcial === 0 || aplicacaoParcial === false ? 'Não' : 'Sim'
    },
  },
}
</script>
