<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="800">
    <custom-snackbar ref="snackbar" />
    <v-card v-if="model">
      <v-toolbar dark color="bg-danger">
        <span class="headline">Deseja Excluir esse registro?</span>
      </v-toolbar>

      <v-form ref="form" lazy-validation v-show="showForm">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <EmpresasSelect
                    v-model="form.empresaId"
                    readonly
                />
              </v-col>
              <v-col cols="6">
                <ProducoesSelect
                    v-model="form.producaoId"
                    :empresa="form.empresaId"
                    readonly
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                    label="Data de Realização"
                    v-model="form.data_realizacao"
                    disabled
                />
              </v-col>
              <v-col cols="6">
                <v-switch
                    v-model.number="form.utilizacao_externa"
                    inset
                    color="success"
                    class="mt-0 pt-0"
                    disabled
                >
                  <template v-slot:label>
                    Utilização Externa:
                    <custom-label
                        class="ml-2"
                        :color="!form.utilizacao_externa ? 'danger' : 'success'"
                        :text="!form.utilizacao_externa ? 'Não' : 'Sim'"
                    />
                  </template>
                </v-switch>
              </v-col>

              <v-col cols="12">
                <TalhoesSelect
                    v-model="form.talhoes"
                    :empresa="form.empresaId"
                    :producao="form.producaoId"
                    disabled
                    multiple
                />
              </v-col>
            </v-row>
            <v-card class="mb-5">
              <v-card-title>
                Produtos
              </v-card-title>
              <v-card-text>
                <v-row class="mt-2" v-for="(produto, index) in form.produtos" :key="index">
                  <v-col class="col-6">
                    <ProdutoSelect
                        :nome="'insumo.' + index"
                        :empresa="form.empresaId"
                        :producao="form.producaoId"
                        v-model="produto.produto_id"
                        readonly
                    ></ProdutoSelect>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        type="number"
                        min="0"
                        step="0.1"
                        v-model="produto.quantidade"
                        :label="'Quantidade (' + produto.unidade_medida + ')'"
                        disabled
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col cols="12">
                <v-textarea
                    v-model="form.observacao"
                    outlined
                    :label="form.utilizacao_externa ? 'Justificativa' : 'Observações'"
                    disabled
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="blue darken-1" @click="close">
            Cancelar
          </v-btn>

          <v-btn text color="blue darken-1" @click="excluir">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-card-text v-show="!showForm">
        <div class="text-center p-5">
          <h4 class="mb-4">
            Aguarde...
          </h4>
          <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: (instance) => ({
    showForm: true,
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    form: function () {
      if (!this.model) {
        return {}
      }

      return {
        empresaId: this.item.empresa_id,
        producaoId: this.item.producao_id,
        talhoes: this.item.talhoes,
        data_realizacao: this.item.data_realizacao.split('/').reverse().join('-'),
        utilizacao_externa: this.item.utilizacao_externa,
        observacao: this.item.observacao,
        produtos: this.item.produtos.map((produto) => ({
          produto_id: produto.produto_id,
          unidade_medida: produto.unidade_medida,
          quantidade: produto.quantidade,
        })),
      }
    },
  },
  methods: {
    close () {
      this.model = false
    },
    excluir () {
      this.showForm = false

      ApiService
        .delete('/agricola/lancamento/insumo/' + this.item.id)
        .then((response) => {
          this.model = false
          this.showForm = true
        })
        .catch((error) => {
          this.$refs.snackbar.show('Problema ao excluir registro', error.message, 'danger', 3000)
          this.showForm = true
        })
    },
  },
}
</script>
