import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardText,{staticClass:"py-0 px-0 px-md-4 py-md-4"},[_c(VDataTable,{ref:"tabela",attrs:{"dense":"","show-expand":"","single-expand":"","item-key":"id","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"no-data-text":_vm.noData,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems,"page":_vm.page,"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"mobile-breakpoint":0},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":[function($event){_vm.perPage=$event},_vm.getData],"update:page":[function($event){_vm.page=$event},_vm.getData]},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.data_realizacao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.validDate(item.data_realizacao)))])]}},{key:"item.utilizacao_externa",fn:function(ref){
var item = ref.item;
return [_c('custom-label',{attrs:{"color":_vm.getColor(item.utilizacao_externa),"text":_vm.getText(item.utilizacao_externa)}})]}},{key:"item.talhoes",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._l((item.talhoes.slice(0, 5)),function(talhao,idx){return _c('custom-label',{key:idx,staticClass:"mr-1",attrs:{"text":talhao.nome}})}),(item.talhoes.length > 5)?_c('custom-label',{attrs:{"text":"..."}}):_vm._e()],2)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.can('agricola.insumo.update'))?_c(VIcon,{attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.editarRegistro(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.can('agricola.insumo.destroy'))?_c(VIcon,{attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.cancelarRegistro(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c(VSpacer),_c('div',[_c(VRow,{staticClass:"justify-center mt-1"},[_c(VCol,{staticClass:"col-lg-6"},[_c('p',[_vm._v("Não há dados para serem exibidos")])])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ViewComponents',{attrs:{"item":item}})],1)]}}],null,true)})],1),_c('EditarLancamentoDialog',{attrs:{"item":_vm.itemSelecionado},on:{"input":_vm.getData},model:{value:(_vm.edicaoDialog),callback:function ($$v) {_vm.edicaoDialog=$$v},expression:"edicaoDialog"}}),_c('ExcluirLancamentoDialog',{attrs:{"item":_vm.itemSelecionado},on:{"input":_vm.getData},model:{value:(_vm.cancelamentoDialog),callback:function ($$v) {_vm.cancelamentoDialog=$$v},expression:"cancelamentoDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }